#root, body, html {
  height: 100%;
  background-color: #fff;
}

.noselect {
  user-select: none;
}

::selection {
  background: #ccc;
}

.strong {
  font-family: Lato, sans-serif;
  font-weight: 400;
}

.background-empty {
  height: 100vh;
  width: 100%;
  visibility: hidden;
}

.background {
  height: 100vh;
  width: 100%;
  position: fixed;
}

#snow {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  pointer-events: none;
}

.bird {
  background-image: url('/images/bird.svg');
  background-size: auto 100%;
  filter: invert(10%) blur(.5px);
  width: 88px;
  height: 125px;
  will-change: background-position;
  animation-name: fly-cycle;
  animation-timing-function: steps(10);
  animation-iteration-count: infinite;
}

.bird--one {
  animation-duration: 1s;
  animation-delay: -.5s;
}

.bird--two {
  animation-duration: 0.9s;
  animation-delay: -0.75s;
}

.bird--three {
  animation-duration: 1.25s;
  animation-delay: -0.25s;
}

.bird--four {
  animation-duration: 1.1s;
  animation-delay: -0.5s;
}

.bird-container {
  position: absolute;
  top: 20%;
  left: -10%;
  transform: scale(0) translateX(-10vw);
  will-change: transform;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  z-index: 10;
}

.bird-container--one {
  animation-name: fly-right-one;
  animation-duration: 15s;
  animation-delay: 0;
}

.bird-container--two {
  animation-name: fly-right-two;
  animation-duration: 16s;
  animation-delay: 1s;
}

.bird-container--three {
  animation-name: fly-right-one;
  animation-duration: 14.6s;
  animation-delay: 9.5s;
}

.bird-container--four {
  animation-name: fly-right-two;
  animation-duration: 16s;
  animation-delay: 10.25s;
}


@keyframes fly-cycle {
  100% {
    background-position: -900px 0;
  }
}

@keyframes fly-right-one {
  0% {
    transform: scale(0.15) translateX(-10vw);
  }

  10% {
    transform: translateY(2vh) translateX(10vw) scale(0.2);
  }

  20% {
    transform: translateY(0vh) translateX(30vw) scale(0.25);
  }

  30% {
    transform: translateY(4vh) translateX(50vw) scale(0.3);
  }

  40% {
    transform: translateY(2vh) translateX(70vw) scale(0.3);
  }

  50% {
    transform: translateY(0vh) translateX(90vw) scale(0.3);
  }

  60% {
    transform: translateY(0vh) translateX(110vw) scale(0.3);
  }

  100% {
    transform: translateY(0vh) translateX(110vw) scale(0.3);
  }
}

@keyframes fly-right-two {
  0% {
    transform: translateY(-2vh) translateX(-10vw) scale(0.3333);
  }

  10% {
    transform: translateY(0vh) translateX(10vw) scale(0.2667);
  }

  20% {
    transform: translateY(-4vh) translateX(30vw) scale(0.4);
  }

  30% {
    transform: translateY(1vh) translateX(50vw) scale(0.3);
  }

  40% {
    transform: translateY(-2.5vh) translateX(70vw) scale(0.3333);
  }

  50% {
    transform: translateY(0vh) translateX(90vw) scale(0.3);
  }

  60% {
    transform: translateY(0vh) translateX(110vw) scale(0.3);
  }

  100% {
    transform: translateY(0vh) translateX(110vw) scale(0.3);
  }
}

.background-image {
  position: absolute;
  height: 100vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.title {
  position: absolute;
  top: 60vh;
  left: calc(35% + 20px);
  transform: translate(-50%, 0);
  font-family: 'Domaine Display', serif;
  font-size: 26px;
  color: #222;
  padding: 5px 10px 5px 10px;
  white-space: nowrap;
  text-transform: uppercase;
  letter-spacing: 1px;
  z-index: 10;
  font-weight: 500;
}

#tt-emoji {
  margin-left: 10px;
}

.main-links {
  position: absolute;
  left: calc(35% + 20px);
  transform: translate(-50%, -10px);
  width: 200px;
  top: 60vh;
  margin-top: 50px;
  display:flex;
  justify-content: space-around;
  font-size: 14px;
  opacity: 0;
  transition: opacity .5s ease-in, transform .5s ease-in;
  z-index: 11;
}

a {
  text-decoration: none !important;
}

.main-link {
  cursor: pointer;
  padding: 3px;
  color: #222;
}

.main-link:hover {
  background-color: #ccc;
}

.main-link-text {
  padding-left: 8px;
}

.more-text {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -10px);
  bottom: 40px;
  color: #222;
  cursor: pointer;
  font-size: 14px;
  opacity: 0;
  transition: opacity .5s ease-in, transform .5s ease-in;
  z-index: 11;
}

.arrow-down {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 20px;
  color: #222;
  cursor: pointer;
  opacity: 0;
  transition: opacity .5s ease-in, transform .5s ease-in;
  z-index: 11;
}

.nav {
  width: 100%;
  height: 50px;
  background-color: rgba(34, 34, 34, .8);
  border-bottom: 2px solid #000;
  z-index: 1000;
}

.nav-wrap {
  width: 100%;
  height: 100%;
  max-width: 500px;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.nav-list {
  padding-left: 20px;
  padding-right: 20px;
  height: 100%;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  color: #fff;
}

.nav-list:hover,
.nav-mobile:hover {
  color: #ccc;
}

.nav-mobile {
  line-height: 50px;
  color: #fff;
  display: none;
  margin: auto;
}

.nav-burger {
  display: none;
}

.nav-burger-item-wrap {
  display: flex !important;
  justify-content: left;
  align-items: center;
}

.nav-burger-item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.nav-burger-inactive-item {
  visibility: hidden;
}

.nav-burger-item-wrap {
  transform: translateX(200px);
}

.nav-burger-item-wrap:nth-child(2) {
  transition-delay: .1s !important;
}

.nav-burger-item-wrap:nth-child(3) {
  transition-delay: .15s !important;
}

.nav-burger-item-wrap:nth-child(4) {
  transition-delay: .2s !important;
}

.nav-burger-item-wrap:nth-child(5) {
  transition-delay: .25s !important;
}

.nav-burger-item-wrap:nth-child(6) {
  transition-delay: .3s !important;
}

.bm-menu-active .nav-burger-item-wrap {
  transform: translateX(0);
  transition: transform .3s ease;
}


/* main page burger menu styles */

/* Position and sizing of burger button */
.nav .bm-burger-button {
  position: fixed;
  width: 24px;
  height: 20px;
  right: 15px;
  top: 15px;
  z-index: 1001 !important;
}

/* Color/shape of burger icon bars */
.nav .bm-burger-bars {
  background: #fff;
}

/* Position and sizing of clickable cross button */
.nav .bm-cross-button {
  height: 24px;
  width: 24px;
  right: auto;
  left: 10px;
  top: 10px;
  transform: scale(1.2);
}

/* Color/shape of close button cross */
.nav .bm-cross {
  background: #aaa;
}

.nav .bm-cross-button:hover .bm-cross {
  background: #eee;
}

/* General sidebar styles */
.nav .bm-menu {
  background: rgba(0, 0, 0, 0.8);
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  backdrop-filter: blur(10px);
}

/* Wrapper for item list */
.nav .bm-item-list {
  color: #fff;
  padding: 0.8em;
}

/* Individual item */
.nav .bm-item {
  display: inline-block;
  padding-top: .25em;
  padding-bottom: .25em;
  cursor: pointer;
  outline: none;
}

.nav .bm-item:hover {
  color: #aaa;
}

.nav .bm-item:hover path {
  fill: #eee;
}

.nav button {
  outline: none;
}

/* Styling of overlay */
.nav .bm-overlay {
  background: rgba(0, 0, 0, 0.1);
  right: 0px;
}

/* subpage burger menu styles */

#outer-container .bm-logo {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}


#outer-container .bm-logo svg {
  transform: rotate(0deg);
  transition: transform 2s ease;
}

#outer-container .bm-logo:hover svg {
  transform: rotate(180deg);
  transition: transform 2s ease;
}

/* Position and sizing of burger button */
#outer-container .bm-burger-button {
  position: fixed;
  z-index: 100;
  padding: 6px;
  margin: 12px;
  transform-origin: center;
  background-color: rgba(255, 255, 255, .8);
  border-radius: 9999px;
  transition: all .5s ease;
}

#outer-container .bm-burger-button:hover {
  background-color: rgba(0, 0, 0, .8);
}

#outer-container .bm-burger-button:hover path {
  fill: #eee;
}

#outer-container .menu-text {
  position: fixed;
  z-index: 101;
  left: 80px;
  top: 20px;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  opacity: 0;
  visibility: hidden;
  text-shadow: -1px -1px 0 rgba(255, 255, 255, .5),
                0   -1px 0 rgba(255, 255, 255, .5),
                1px -1px 0 rgba(255, 255, 255, .5),
                1px  0   0 rgba(255, 255, 255, .5),
                1px  1px 0 rgba(255, 255, 255, .5),
                0    1px 0 rgba(255, 255, 255, .5),
               -1px  1px 0 rgba(255, 255, 255, .5),
               -1px  0   0 rgba(255, 255, 255, .5);
  transition: all .5s ease;
}

#outer-container .menu-wrap:hover .menu-text {
  left: 60px;
  visibility: visible;
  opacity: 1;
}

/* Position and sizing of clickable cross button */
#outer-container .bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
#outer-container .bm-cross {
  background: #555;
}

#outer-container .bm-cross-button:hover .bm-cross {
  background: #aaa;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
#outer-container .bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
#outer-container .bm-menu {
  background: #eee;
  background: linear-gradient(90deg, #ddd 0%, #eee 20%, #eee 100%);
  font-size: 1.15em;
}

/* Wrapper for item list */
#outer-container .bm-item-list {
  color: #fff;
}

/* Individual item */
#outer-container .bm-item {
  color: #555;
  display: flex !important;
  justify-content: center;
  outline: none;
}

#outer-container .bm-menu-wrap a {
  padding-top: .25em;
  padding-bottom: .25em;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0;
  transition: all .3s ease;
}

#outer-container .bm-current-item {
  background-color: rgba(0, 0, 0, .05);
  cursor: default !important;
}

#outer-container .bm-menu-wrap a:not(.bm-current-item):hover {
  color: #eee;
  background-color: #0d8aba;
  letter-spacing: 3px;
}

#outer-container button {
  outline: none;
}

/* Styling of overlay */
#outer-container .bm-overlay {
  background-image: linear-gradient(to right, rgba(0, 0, 0, .8), rgba(0, 0, 0, .2)) !important;
}

/* fix transition glitch on Chrome and scrolling glitch on mobile Safari */
#page-wrap {
  -webkit-overflow-scrolling: touch;
  transform-origin: left center !important;
}

.nav-logo {
  margin-bottom: 30px;
  display: flex !important;
  justify-content: center;
}

.nav-bar {
  width: 100px;
  position: absolute;
  top: 0px;
  left: -100px;
  height: 2px;
  background: #fff;
  transition: left .3s;
}

.about-page,
.skill-page,
.research-page,
.project-page,
.more-page {
  width: 100%;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 30px;
}

.about-page,
.research-page,
.project-page {
  min-height: 100vh;
}

.more-page {
  min-height: calc(100vh + 30px);
}

.skill-page,
.project-page {
  background-color: #f5f5f5;
}

.skill-page {
  height: 100vh;
  min-height: 850px;
  position: absolute;
}

.contact-page {
  padding-top: 100px;
  width: 100%;
  height: 400px;
  background-color: #222;
  text-align: center;
  position: relative;
}

.logo {
  height: 100px;
  width: 100px;
  margin: 0 auto;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: 1px solid #ccc;
  transform-origin: 50% 50%;
  stroke-width: 1px;
}

.logo path:first-child {
  stroke: #0d8aba;
}

.logo path:last-child {
  stroke: #222;
}

.logo:hover path:first-child {
  fill: #0d8aba;
  fill-opacity: 0;
  stroke-dasharray: 164;
  stroke-dashoffset: 164;
  animation: logo-anim 1s linear forwards;
}

.logo:hover path:last-child {
  fill: #222;
  fill-opacity: 0;
  stroke-dasharray: 86;
  stroke-dashoffset: 86;
  animation: logo-anim 1s linear forwards;
}

@keyframes logo-anim {
  90% {
    stroke-dashoffset: 0;
    fill-opacity: 0;
  }
  100% {
    stroke-dashoffset: 0;
    fill-opacity: 1;
  }
}

.about-wrap {
  margin: 15px auto;
  max-width: 80%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}

.about-text {
  width: 600px;
  max-width: 80%;
  text-align: left;
  color: #222;
  font-size: 16px;
}

.about-text > div {
  padding: 10px;
  opacity: 0;
  transform: translateX(-100px);
}

.about-text-paragraph {
  text-align: justify;
  hyphens: auto;
  transition: transform 1s ease-out;
}

.about-text-paragraph:hover {
  transform: scale(1.02);
  transition: transform .3s ease-out;
}

.about-text-paragraph a {
  color: #000;
  border-bottom: solid 1px #aaa;
}

.about-text-paragraph a:hover {
  color: #000;
  border-bottom: solid 1px #000;
}

.about-links {
  font-size: 13px;
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 12px 0px 0px 10px;
  opacity: 0;
  transform: translateY(100px);
}

.about-link {
  padding: 5px;
  text-align: left;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 40px;
  width: 40px;
}

.about-link-main {
  transition: all .75s ease-out;
}

.about-link-secondary {
  position: absolute;
  opacity: 0;
  transform: scale(0);
  transition: all .75s ease-out;
}

.about-link:hover .about-link-main {
  opacity: 0;
  transform: scale(1.5);
  transition: all .75s ease-out;
}

.about-link:hover .about-link-secondary {
  opacity: 1;
  transform: scale(1);
  transition: all .75s ease-out;
}

.circle-icon {
  border-radius: 999px;
  background-color: #555;
  color: #fff;
  width: 29px;
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-title {
  height: 50px;
  font-size: 28px;
  margin: 0px auto 0px auto;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: #222;
}

.contact-page .section-title {
  color: #fff;
}

.underline {
  padding-top: 5px;
  padding-bottom: 20px;
  border-top: 2px solid #aaa;
  width: 60px;
  height: 5px;
  margin: 0 auto 20px auto;
  opacity: 0;
  transform: translateX(-50px);
}

.contact-page .underline {
  border-top-color: #fff;
}

.number-cards,
.projects {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  width: 90%;
  flex-wrap: wrap;
  opacity: 0;
  transform: translateY(100px);
}

.number-cards {
  padding-top: 40px;
  padding-bottom: 40px;
}

.projects {
  max-width: 1300px;
}

.projects-slider .slick-slide {
  display: flex !important;
  justify-content: center;
}

.number-cards-title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  font-size: 20px;
  color: #aaa;
  font-style: italic;
}

.number-cards-title-line {
  width: 50px;
  height: 2px;
  border-bottom: 1px solid #aaa;
}

.number-cards-title:hover .number-cards-title-line {
  animation: number-cards-title-anim 1.5s normal ease;
}

@keyframes number-cards-title-anim {
  0%,100% {
    width: 50px;
  }
  50% {
    width: 0px;
  }
}

.number-cards .number-card {
  margin: -1px 0 0 -1px;
}

.number-card-slider-wrap {
  opacity: 0;
  transform: translateY(100px);
}

.number-card-slider {
  margin-top: 20px;
  margin-bottom: 20px;
}

.number-card-slider .number-card {
  margin: 0 auto;
  transform: scale(.9);
}


.slick-next {
  right: 30px !important;
}

.slick-prev {
  left: 30px !important;
}

.slick-next,
.slick-prev {
  width: 32px !important;
  height: 32px !important;
  z-index: 10 !important;
}

.number-card {
  width: 250px;
  height: 200px;
  position: relative;
}

.number-card .card {
  border-radius: 0px;
}


.number-card .card-body {
  padding: 15px;
}

.number {
  font-size: 48px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
}

.number-desc {
  height: 80px;
  font-family: 'Oswald', sans-serif;
  font-size: 18px;
}

.number-card-front {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 1;
}

.number-card-front .card {
  border: 1px solid rgba(0,0,0,0.05);
}

.number-card-back {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  transform: scaleY(0);
}

.number-card-back .card-body {
  width: 100%;
  height: 100%;
  background-color: #555;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.number-card-back a {
  color: #fff;
  text-decoration: underline !important;
}

.number-card-back a:hover {
  color: #ccc;
}

.project-card {
  margin: 20px;
  width: 250px;
  height: 300px;
  background-color: #fff;
}

.project-card.card {
  border: none;
  border-radius: 0;
}

.project-card > img {
  transition: max-height .5s ease;
  border-radius: 0;
  filter: grayscale(40%);
  transition: all .5s;
}

.project-card > img:hover {
  filter: grayscale(0%);
  transition: all .5s;
}

.project-card .card-body {
  opacity: 0;
  transform: translateX(-50px);
}

.project-card-title {
  font-family: 'Oswald', sans-serif;
}

.project-card-expanded {
  transition: opacity .5s;
}

.project-card-expanded .badge {
  background-color: #fff;
  color: #aaa;
  border: 1px solid #aaa;
  margin: 3px;
}

.project-card-expanded a {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #555;
}

.project-card-expanded a:hover {
  color: #555;
}

.project-card-link {
  border-radius: 15px;
  width: 30px;
  height: 30px;
  border: 1px solid #555;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: 30px;
}

.project-card-link:not(.nolink):hover {
  width: 100px;
}

.project-card-link span {
  transform: scaleX(0);
  width: 0;
  white-space: nowrap;
}

.project-card-link:not(.nolink):hover span {
  transform: scaleX(1);
  width: auto;
  padding: 5px;
  transition: transform .5s;
}

.project-card-link.nolink {
  border: 1px solid #eee;
}

.project-card .card-text {
  line-height: 1.2;
}

.section-text-wrap {
  margin: 0px;
  width: 100%;
}
.section-text-wrap > div {
  opacity: 0;
  transform: translateX(-100px);
}

.section-text {
  margin: 15px auto 40px auto;
  width: 600px;
  max-width: 80%;
  text-align: left;
  color: #222;
  font-size: 16px;
}

.skill-chart-wrap {
  width: 80%;
  height: 500px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: top;
  flex-wrap: wrap;
}

.skills {
  width: 60%;
  height: 100%;
  margin: 0;
}

.skill-switch {
  position: fixed;
  width: 120px;
  height: 50px;
  bottom: 20vh;
  background-color: #555;
  opacity: .5;
  padding: 10px;
  border-radius: 10px;
  transform: translateX(-72px);
  cursor: pointer;
  z-index: 99;
  transition: all .2s;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: all .3s;
}

.skill-switch-init {
  transform: translateX(-120px);
  transition: all .3s;
}

.skill-switch:hover {
  transform: translateX(-8px);
  transition: all .3s;
}

.skill-switch > div {
  width: 70px;
  word-wrap: break-word;
  color: #f5f5f5;
  font-weight: bold;
  font-size: 12px;
  padding-right: 5px;
}

.scroll-to-top,
.scroll-to-content {
  position: absolute;
  background-color: rgba(230, 230, 230, .8);
  margin: 0 auto;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #555;
  overflow: hidden;
  cursor: pointer;
  z-index: 11;
}

.scroll-to-top {
  transform: translate(-50%, -50%);
  border: 4px solid #fff;
}

.scroll-to-content {
  transform: translate(-50%);
  bottom: 50px;
}

.scroll-to-top:hover,
.scroll-to-content:hover {
  background-color: rgba(170, 170, 170, .8);
}

.scroll-to-top:hover > .scroll-arrow {
  animation: scroll-arrow-anim-up 1s normal ease;
}

.scroll-to-content:hover > .cover-scroll-arrow {
  animation: scroll-arrow-anim-down 1s normal ease;
}

@keyframes scroll-arrow-anim-up {
  0%,100% {
    transform: translate(0%,0%);
    opacity: 1;
  }
  49% {
    transform: translate(0%, -125%);
    opacity: 1;
  }
  50% {
    transform: translate(0%, -125%);
    opacity: 0;
  }
  51% {
    transform: translate(0%, 125%);
    opacity: 0;
  }
}

@keyframes scroll-arrow-anim-down {
  0%,100% {
    transform: translate(0%,0%);
    opacity: 1;
  }
  49% {
    transform: translate(0%, 125%);
    opacity: 1;
  }
  50% {
    transform: translate(0%, 125%);
    opacity: 0;
  }
  51% {
    transform: translate(0%, -125%);
    opacity: 0;
  }
}

.scroll-to-top-radius-left {
  position: absolute;
  margin: 0 auto;
  z-index: 10;
  left: 50%;
  transform: translateY(-2px) scale(-1,1);
  transform-origin: 0 0;
}
.scroll-to-top-radius-right {
  position: absolute;
  margin: 0 auto;
  z-index: 10;
  left: 50%;
  transform: translateY(-2px);
}

.skill-category {
  padding: 2px;
  margin: 5px;
  width: 250px;
  font-size: 16px;
  font-family: 'Oswald', sans-serif;
  color: #555;
  cursor: pointer;
}

.skill-category-line {
  border-top: 1px dashed #aaa;
  width: 30px;
  height: 2px;
  margin: 10px auto 10px auto;
}

.skill-category-active,
.skill-category:hover {
  font-weight: 600;
}

.skill-category-bracket {
  padding: 0 5px 0 5px;
  visibility: hidden;
}

.skill-category-active .skill-category-bracket,
.skill-category:hover .skill-category-bracket {
  visibility: visible;
}

.skill-bar-empty {
  fill: #555;
}

.skill-name-background {
  fill: #fff;
}

.skill-name {
  text-anchor: middle;
  alignment-baseline: middle;
  font-size: 14px;
}

.skill-chart-wrap {
  position: absolute;
  width: 100%;
  margin: 100px auto 0px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.skill-logo {
  width: 60px;
  height: 60px;
  opacity: 0;
  transform: scale(0);
  filter: contrast(0%) brightness(50%) invert(100%) drop-shadow(0 0 1px #aaa);
}

.skill-logo > img {
  object-fit: contain;
}

@media (max-width: 750px) {
  .skill-logo {
    display: none;
  }
}

@media (max-width: 650px) {
  .skill-categories {
    display: none;
  }
}

.github-name,
.github-byte {
  text-anchor: end;
  font-size: 14px;
}

.github-x-axis .tick line {
  display: none;
}

.github-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 160px;
}

.github-card {
  width: 30vw;
  max-width: 150px;
  height: 125px;
  padding: 5px;
}

.github-card .card {
  border: none;
  border-radius: 0;
}

.github-card-desc {
  font-size: 14px;
  white-space: nowrap;
}

.github-card-number {
  font-size: 24px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  transition: transform .5s ease;
}

.github-card:hover .github-card-number {
  transform: scale(1.2);
  transition: transform .5s ease;
}

.skill-page .more-button {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  top: 650px;
}

.keywords-wrap {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px auto 10px auto;
  flex-wrap: wrap;
}

.keywords-title {
  font-weight: bold;
  transform: translateX(-100px);
}

.keywords-title,
.keyword {
  margin: 0 5px 0 5px;
  opacity: 0;
}

.keyword {
  border-radius: 999px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

.researches {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.research {
  width: 100%;
  min-height: 20vw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  opacity: 0;
  transform: translateX(100px);
  margin-top: 10px;
  margin-bottom: 10px;
}

.research-image-wrap {
  width: 20vw;
  height: 20vw;
  min-width: 120px;
  min-height: 120px;
  max-width: 300px;
  max-height: 300px;
  clip-path: circle(50%);
}

.research-image {
  width: 100%;
  height: 100%;
  border-radius: 9999px;
  transition: transform 1s ease-out;
}

.research:hover .research-image {
  transform: scale(1.1);
  transition: transform 1s ease-out;
}

.research-text {
  width: 50vw;
  text-align: left;
  min-width: 300px;
  max-width: 600px;
  padding: 5px;
}

.research-title {
  font-size: 20px;
  font-family: 'Oswald', sans-serif;
}

.research-detail {
  font-size: 15px;
  margin-top: 5px;
  margin-bottom: 15px;
}

.research-button {
  margin-top: 3px;
  border-radius: 999px !important;
}

.more-button {
  position: relative;
  width: 250px;
  height: 50px;
  margin: 0 auto;
  border-radius: 9999px;
  border: 1px solid #555;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 50px;
  margin-bottom: 50px;
}

.more-button-front {
  color: #555;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.more-button:hover .more-button-front {
  color: #fff;
  transition: color .5s;
}

.more-button:hover .more-button-arrow {
  transform: translateX(12px);
  transition: transform .5s;
}

.more-button-arrow {
  padding-left: 5px;
}

.more-button-background {
  position: absolute;
  left: 0px;
  width: 0%;
  opacity: 0;
  height: 100%;
  background-color: #555;
  border-radius: 9999px;
}

.more-button:hover .more-button-background {
  width: 100%;
  opacity: 1;
  transition: all .5s;
}

.more-items {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 90%;
  margin: 0 auto;
}

.more-item {
  width: 260px;
  height: 260px;
  position: relative;
  margin: 0;
  text-align: center;
  cursor: auto;
  transform: scale(0.85);
}

.more-circle-svg {
  position: absolute;
  display: block;
  margin: auto;
}

.more-circle {
  fill: transparent;
  stroke: #f5f5f5;
  stroke-width: 10px;
  stroke-dasharray: 785.3982px;
  stroke-dashoffset: 0px;
  stroke-linecap: square;
  transform-origin: 50% 50%;
  transform: rotate(-90deg);
}

.more-circle-back {
  fill: transparent;
  stroke: #555;
  stroke-width: 10px;
  stroke-dasharray: 785.3982px;
  stroke-dashoffset: 785.3982px;
  stroke-linecap: square;
  transform-origin: 50% 50%;
  transform: scale(1, -1) rotate(90deg);
}

.more-item:hover .more-circle {
  stroke-dashoffset: 785.3982px;
  transition: stroke-dashoffset 1s;
}

.more-item:hover .more-circle-back {
  stroke-dashoffset: 0px;
  transition: stroke-dashoffset 1s;
}

.more-circle-anim {
  fill: transparent;
  stroke: #fff;
  stroke-width: 12px;
}

.more-item-title,
.more-item-number,
.more-item-description {
  position: absolute;
  width: 100%;
  color: #000;
}

.more-item-logo {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.more-item:hover .more-item-logo {
  display: none;
}

.more-item-title {
  top: 50px;
  text-transform: uppercase;
}

.more-item-number {
  top: 90px;
  font-size: 48px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
}

.more-item-description {
  bottom: 65px;
  font-size: 24px;
  font-family: 'Oswald', sans-serif;
}

.more-item-pic {
  position: absolute;
  margin: 9px;
  width: 242px;
  height: 242px;
  border-radius: 125px;
  background-size: cover;
  opacity: 0;
  filter: brightness(50%);
}

.more-item:hover .more-item-pic {
  opacity: 1;
  cursor: pointer;
  transition: opacity ease-out .5s;
}

.more-item:hover .more-item-title,
.more-item:hover .more-item-number,
.more-item:hover .more-item-description {
  color: #fff;
  text-shadow: 0px 0px 2px #222;
  cursor: pointer;
  transition: all ease-out .5s;
}

.contact-email {
  font-size: 20px;
  font-family: 'Exo 2', sans-serif;
  font-weight: 200;
}

.contact-email > span:nth-child(3) {
  padding-left: 2px;
  padding-right: 2px;
}

@keyframes email-anim {
  0%,
  100% {
    transform: translateX(0%);
    transform-origin: 50% 50%;
  }
  15% {
    transform: translateX(-30px) rotate(-6deg);
  }
  30% {
    transform: translateX(15px) rotate(6deg);
  }
  45% {
    transform: translateX(-15px) rotate(-3.6deg);
  }
  60% {
    transform: translateX(9px) rotate(2.4deg);
  }
  75% {
    transform: translateX(-6px) rotate(-1.2deg);
  }
}


a > .contact-email {
  color: #fff !important;
}

a:hover > .contact-email {
  color: #ccc !important;
  animation: email-anim 1s both;
}

.at {
  padding: 2px;
}

.tooltip {
  transition: opacity .5s;
}

.footer {
  position: absolute;
  bottom: 30px;
  left: 50px;
  right: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
}

.footer-icon {
  margin-right: 10px;
  cursor: pointer;
  color: #666;
}

.footer-icon a {
  color: #666 !important;
}

.footer-icon:hover,
.footer-icon a:hover {
  color: #999 !important;
}

.footer-tooltip {
  background-color: #fff !important;
  border-radius: 15px !important;
  color: #222 !important;
  font-size: 12px !important;
}

.footer-tooltip-arrow::before {
  border-top-color: #fff !important;
}

.wechat-tooltip {
  width: 150px !important;
  height: 150px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-dotted-text {
  font-family: monospace;
  border-bottom: 1px dotted #222;
  color: #222;
}

a .footer-dotted-text:hover {
  color: #999;
  border-bottom: 1px dotted #999;
}

.footer-right {
  color: #fff;
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  text-shadow: 0 0 3px #000;
}

.heart {
  padding: 3px;
  color: #f00;
  opacity: 1;
  transform: scale(1) translateY(-1px);
  transform-origin: 50% 50%;
  transition: transform .5s;
}

.footer-right:hover .heart,
.cover-footer:hover .heart {
  transform: scale(1.3) translateY(-1px) !important;
  transition: transform .5s;
}

.footer-background {
  position: absolute;
  height: 250px;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0px;
  bottom: 0;
  left: 0;
  background-blend-mode: lighten;
  background-color: #222;
  background-position: center bottom;
}

.cover {
  height: 100vh;
  width: 100%;
  position: relative;
  background-color: #fff;
}

.cover-image {
  height: 100vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.cover-title {
  position: absolute;
  top: 30%;
  transform: translateX(-50%);
  left: 50%;
  display: flex;
  align-items: center;
  font-size: 36px;
  margin-top: 20px;
  z-index: 100;
  background-color: rgba(0,0,0,.1);
  color: #222;
}

.cover-title-cross {
  display: flex;
}

.cover-title-text {
  padding-left: 10px;
  padding-right: 10px;
  font-family: 'Montserrat', sans-serif;
  white-space: nowrap;
}

.cover-quote {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translateX(-50%);
  max-width: 350px;
  font-style: italic;
  text-align: center;
}

.cover-author {
  font-style: normal;
  padding-top: 10px;
}

.cover-text {
  margin: 60px 50px -50px 50px;
  display: flex;
  justify-content: center;
  color: #999;
}

.cover-text a {
  color: #999;
  border-bottom: dashed 1px #999;
}

.cover-text a:hover {
  border-bottom: dashed 1px #555;
  color: #555;
}

.cover-content {
  min-height: calc(100vh - 70px);
  width: 100%;
  overflow: hidden;
}

.cover-footer {
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  padding-top: 25px;
  padding-bottom: 25px;
  height: 70px;
}

.book-search {
  justify-self: end;
  flex-basis: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.book-searchbox {
  padding-left: 10px;
  padding-right: 20px;
  width: 200px;
  border: none;
  outline: none;
}

.book-searchbox:focus {
  border: none;
}

.book-searchbox::placeholder {
  color: #aaa;
}

.reading-list {
  padding-top: 100px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 auto;
}

.book {
  padding: 20px;
  cursor: pointer;
}

.book > img {
  border-radius: 10px;
}

.book:hover > img {
  transform: rotate(5deg);
  box-shadow: 5px -5px 20px rgba(0,0,0,.2);
  transition: all .5s;
}

.book-title {
  font-size: 14px;
  margin-top: 15px;
  width: 143px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title-underline {
  width: 100%;
  height: 2px;
  border-top: solid 1px #ccc;
  opacity: 0;
  transform: translateY(50px);
}

.book:hover .title-underline,
.portfolio-work:hover .title-underline {
  opacity: 1;
  transform: translateY(0);
  transition: all .5s;
}

.book-detail {
  padding-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
}

.book-detail-background {
  position: absolute;
  top: 300px;
  bottom: -100px;
  left: 0;
  width: 100%;
  background-color: #eee;
}

.book-detail-img {
  padding: 100px 75px 100px 75px;
  position: relative;
}

.book-detail-img > a {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  opacity: 0;
  border: 2px solid #fff;
  color: #fff !important;
}

.book-detail-img > img {
  border-radius: 5px;
  box-shadow: 0px 20px 50px rgba(0, 0, 0, .2);
}

.book-detail-img:hover > img {
  transition: all .5s ease !important;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, .2);
}

.book-detail-info {
  max-width: 90vw;
  width: 600px;
  padding: 100px 75px 100px 75px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
  transform: translateY(100px);
}

.book-detail-title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 32px;
  text-align: center;
}

.book-detail-author {
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  border-top: 1px solid #999;
  border-bottom: 1px solid #999;
  display: inline-block;
  color: #999;
  font-weight: normal;
}

.book-detail-tags {
  margin-top: 25px;
}

.book-detail-tag {
  background-color: #fff;
  margin: 5px;
  padding: 8px;
  font-size: 12px;
  border-radius: 9999px;
  color: #999;
  font-weight: normal;
  white-space: nowrap;
}

.book-detail-links {
  display: flex;
  justify-content: center;
  margin-top: 60px;
}

.book-detail-link {
  margin: 5px;
  background-color: #555;
  border-radius: 5px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.book-detail-link:hover {
  background-color: #999;
  transition: all .5s;
}

.book-detail-link > a {
  color: #fff !important;
}

.book-quotes {
  background-color: #555;
  text-align: center;
  min-height: 50vh;
  padding-top: 100px;
  padding-bottom: 100px;
  transform: translateY(100px);
}

.book-quotes-icon {
  position: absolute;
  background-color: #eee;
  margin: -100px auto;
  width: 80px;
  height: 80px;
  border-radius: 40px;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #555;
  overflow: hidden;
  z-index: 11;
  transform: translate(-50%, -50%);
  padding-top: 40px;
  font-family: 'Playfair Display', serif;
  font-size: 150px;
  font-weight: bold;
}

.book-quotes .section-title {
  color: #fff;
}

.book-quotes .underline {
  border-top-color: #fff;
}

.book-quote {
  width: 60%;
  margin: 30px auto 30px auto;
  color: #fff;
  text-align: center;
  padding: 30px;
  line-height: 2;
  font-family: Georgia, "Nimbus Roman No9 L", "Songti SC", "Noto Serif CJK SC", "Source Han Serif SC", "Source Han Serif CN", STSong, "AR PL New Sung", "AR PL SungtiL GB", NSimSun, SimSun, "TW\-Sung", "WenQuanYi Bitmap Song", "AR PL UMing CN", "AR PL UMing HK", "AR PL UMing TW", "AR PL UMing TW MBE", PMingLiU, MingLiU, serif;
  font-weight: normal;
  position: relative;
}

.book-quote-left {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 20px;
  border-top: solid 3px #fff;
  border-left: solid 3px #fff;
  border-bottom: solid 3px #fff;
  opacity: 0;
}

.book-quote-right {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 20px;
  border-top: solid 3px #fff;
  border-right: solid 3px #fff;
  border-bottom: solid 3px #fff;
  opacity: 0;
}

.book-quote:hover .book-quote-left,
.book-quote:hover .book-quote-right {
  opacity: 1;
  transition: opacity .5s;
}

.book-quote ol,
.book-quote ul {
  text-align: left;
}

.bold {
  font-weight: bold;
}

.cv-sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cv-photo {
  width: 200px;
  height: 200px;
  position: relative;
  transition: all .5s;
}

.cv-photo > img {
  position: absolute;
  border-radius: 100px;
}

.cv-photo-background {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ebf8f6;
  border-radius: 100px;
  transition: all .5s;
}

.cv-photo:hover {
  transform: translateY(5px);
  transition: all .5s;
}

.cv-photo:hover .cv-photo-background {
  background-color: #c5ebe5;
  transform: scale(.8);
  transition: all .5s;
}

.cv-name {
  font-size: 28px;
  padding-top: 10px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

.cv-nickname {
  position: absolute;
  top: 0;
  margin: 10px auto;
  transform: translateY(30px);
  opacity: 0;
  z-index: 10;
  background-color: #fff;
  transition: all .5s;
}

.cv-name:hover .cv-nickname {
  transform: translateY(0px);
  opacity: 1;
  transition: all .5s;
}

.cv-basic {
  margin-top: 20px;
}

.cv-basic-info {
  padding-left: 15px;
}

.cv-basic-row {
  white-space: nowrap;
}

.cv-basic-row a {
  color: #555;
}

.cv-basic-row:hover a {
  color: #000;
  border-bottom: dashed 1px #000;
}

.cv-basic-row:hover svg {
  filter: brightness(20%);
  transition: filter .5s;
}

.cv-section {
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
}

.cv-section .badge {
  margin-top: 3px;
  margin-left: 5px;
  margin-bottom: 5px;
  background-color: #eee;
  color: #000;
  font-weight: normal;
}

.cv-section-title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: #0d8aba;
  padding-top: 30px;
  display: flex;
  align-items: center;
}

.cv-section-title-text-wrap {
  background-color: #fff;
  z-index: 10;
  padding-right: 10px;
  display: inline-flex;
  align-items: center;
}

.cv-section-title-text {
  padding-left: 10px;
}

.cv-section-line {
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  border-top: 1px solid #0d8aba;
}

.cv-item {
  margin-top: 10px;
}

.cv-link {
  color: #000;
}
.cv-link:hover {
  color: #000;
  border-bottom: dashed 1px #000;
}

.cv-item-title .under {
  border-bottom: solid 1px #000;
}

.cv-item-right {
  color: #555;
  text-align: right;
}

.cv-section ul {
  margin-top: 0px;
  margin-bottom: 0px;
}

.cv-details {
  font-size: 80%;
}

.cv-link-gray {
  color: #aaa;
}

.cv-link-gray:hover {
  color: #000;
  border-bottom: dashed 1px #000;
}

.cv-courses span:not(:first-child):not(:last-child)::after,
.cv-review span:not(:last-child)::after {
  content: ', ';
  padding-right: 3px;
}

.cv-courses a {
  color: #aaa;
  padding-left: 3px;
  font-size: 85%;
}

.cv-courses a:hover {
  color: #555;
}

.cv-download {
  margin-top: 20px;
  position: relative;
  transition: all .2s ease;
}

.cv-download-text {
  position: absolute;
  font-size: 14px;
  color: #555;
  padding: 2px;
  white-space: nowrap;
  opacity: 0;
  transition: all .2s ease;
}

.cv-download:hover .cv-download-text {
  opacity: 1;
  transition: all .2s ease;
}

.cv-download:hover {
  transform: translateX(-45px);
  transition: all .2s ease;
}

.portfolio-list,
.photography-list {
  margin: 100px 50px 100px 50px;
}

.portfolio-img {
  background-size: cover;
  border-radius: 5px;
  transition: all .5s ease;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  cursor: pointer;
}

.portfolio-img-white {
  background-color: #f5f5f5;
  background-blend-mode: darken;
}

.portfolio-year {
  background-color: #aaa;
  border-radius: 3px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px 5px 10px;
  transform: translateY(-8px);
  font-weight: bold;
  color: #fff;
  opacity: 0;
  transition: all .5s ease;
}

.portfolio-work {
  padding-bottom: 8px;
}

.portfolio-work:hover .portfolio-img,
{
  transform: scale(1.05);
  box-shadow: inset 0px 0px 30px rgba(0,0,0,.1);
  transition: transform .5s ease;
}

.portfolio-work:hover .portfolio-img {
  transform-origin: center bottom;
}

.portfolio-work:hover .portfolio-year {
  opacity: 1;
  transition: all .5s ease;
}

.portfolio-title-wrap {
  display: flex;
  justify-content: center;
}

.portfolio-title {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 4px;
  cursor: pointer;
}

.portfolio-link {
  color: #555;
  transform: translateY(-1px);
  margin-left: 6px;
}

.portfolio-link:hover {
  color: #aaa;
}

.portfolio-link-lightbox {
  transform: translateY(1px) !important;
}

.portfolio-info {
  font-size: 12px;
  font-weight: normal;
  font-variant: normal;
  margin-left: 15px;
}

.photography-hover {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  float: left;
  overflow: hidden;
  position: relative;
  text-align: center;
}

.photography-hover .photography-overlay {
  width: 100%;
  position: absolute;
  overflow: hidden;
  left: 0;
  top: auto;
  bottom: 0;
  padding: 5px;
  height: 40px;
  background: rgba(255,255,255,0.7);
  transition: transform .3s;
  transform: translate3d(0,100%,0);
  visibility: hidden;
  cursor: default;
}

.photography-hover .photography-overlay svg {
  margin-left: 2px;
  margin-right: 2px;
  cursor: pointer;
}

.photography-hover .photography-overlay a {
  float: right;
  transform: translate3d(0,200%,0);
  transition: transform .3s;
  cursor: pointer;
  color: #555 !important;
  visibility: visible;
}

.photography-hover:hover .photography-overlay a {
  transform: translate3d(0,0,0);
}

.photography-hover:hover .photography-overlay a:nth-child(3) {
  transition-delay: .05s;
}

.photography-hover:hover .photography-overlay a:nth-child(2) {
  transition-delay: .1s;
}

.photography-hover:hover .photography-overlay a:nth-child(1) {
  transition-delay: .15s;
}

.photography-hover .photography-overlay a:hover {
  color: #222 !important;
}

.photography-hover:hover .photography-overlay {
  transform: translate3d(0,0,0);
  visibility: visible;
}

.photography-hover img {
  display: block;
  position: relative;
  transition: all .3s ease-in;
  cursor: pointer;
}

.photography-hover:hover img {
  transform: translate3d(0,-10%,0) scale(1.2);
  filter: brightness(80%);
}

.photography-caption {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.photography-btns {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.photography-btn-text {
  font-size: 12px;
  display: inline-block;
  color: #555;
}

.unsplash-error {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #555;
}

.unsplash-error a {
  color: #555;
  border-bottom: dashed 1px #555;
}

.unsplash-error a:hover {
  border-bottom: dashed 1px #999;
  color: #999;
}

.unsplash-error-title {
  font-size: 48px;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 2px;
}

.unsplash-link {
  background-color: #fff;
  padding: 2px 6px;
  display: inline-flex;
  align-items: center;
  border-radius: 3px;
  margin: 0px 3px;
}

.unsplash-logo {
  height: 12px;
  width: auto;
  position: relative;
  vertical-align: middle;
  fill: #555;
}

.unsplash-link:hover {
  background-color: #555;
}

.unsplash-link:hover .unsplash-logo {
  fill: #fff;
}

.unsplash-download-link {
  background-color: #fff;
  color: #555;
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  height: 24px;
  width: auto;
  margin: 0px 3px;
  padding: 0px 4px;
}

.unsplash-download-link:hover {
  background-color: #555;
  color: #fff;
}

.unsplash-link:hover .photography-btn-text,
.unsplash-download-link:hover .photography-btn-text {
  color: #fff;
}

.unsplash-link:hover,
.unsplash-link:hover .unsplash-logo,
.unsplash-download-link:hover,
.unsplash-link:hover .photography-btn-text,
.unsplash-download-link:hover .photography-btn-text {
  transition: all ease .5s;
}

#lightboxBackdrop {
  backdrop-filter: blur(3px);
}

#lightboxBackdrop svg {
  vertical-align: inherit;
}

#lightboxBackdrop figcaption > a {
  display: inline-flex;
  align-items: center;
}

.places-map-wrap {
  margin: 100px 50px 0px 50px;
  position: relative;
}

.places-map {
  width: 100%;
  height: auto;
  margin: 0 auto;
  max-height: 80vh;
}

.places-geo-pointer {
  cursor: pointer;
}

.places-geo-hover {
  fill: #0d8aba !important;
  stroke: #0d8aba !important;
}

.places-marker-text {
  fill: #555;
  font-size: 10px;
  font-weight: bold;
  font-family: Oswald, sans-serif;
  pointer-events: none;
}

.places-marker-info {
  fill: #555;
  font-size: 8px;
  font-weight: bold;
  pointer-events: none;
}

.places-marker {
  stroke: #0d8aba;
  stroke-width: 1px;
}

.place-marker-hover {
  fill: #fff;
}

.flight-toggle-wrap {
  position: absolute;
  display: flex;
  align-items: center;
  transform: translateX(-50%) translateY(-60px);
  left: 50%;
}

.flight-toggle {
  margin-right: 10px;
}

.flight-toggle .react-toggle-track {
  background-color: #ddd !important;
}

.flight-toggle:hover .react-toggle-track {
  background-color: #aaa !important;
}

.flight-toggle .react-toggle-thumb {
  border-color: #ddd !important;
}

.flight-toggle:hover .react-toggle-thumb {
  border-color: #aaa !important;
}

.flight-toggle.react-toggle--checked .react-toggle-track {
  background-color: #0d8aba !important;
}

.flight-toggle.react-toggle--checked:hover .react-toggle-track {
  background-color: #0a678a !important;
}

.flight-toggle.react-toggle--checked .react-toggle-thumb {
  border-color: #0d8aba !important;
}

.flight-toggle.react-toggle--checked:hover .react-toggle-thumb {
  border-color: #0a678a !important;
}

.notfound-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

.notfound-oops {
  font-family: 'Montserrat', sans-serif;
  font-size: calc(12px + 12vw);
  font-weight: bold;
  line-height: 1.1;
  color: #0d8aba;
  background: url(/images/joel-filipe-KZa4fREZoKk-unsplash.jpg);
  background-size: contain;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.notfound-text {
  font-size: calc(12px + 1.2vw);
  text-transform: uppercase;
  margin-top: 10px;
}

.text-tooltip {
  hyphens: none;
}

span:lang(grc) {
  font-family: 'Noto Sans', sans-serif !important;
  font-weight: 200;
}

/* for small screens */
@media (max-width: 964px) {
  .about-links {
    width: 600px;
    max-width: 80%;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }

  .about-link {
    width: auto !important;
    padding: 5px;
  }
}

@media (max-width: 500px) {
  .nav-list {
    display: none;
  }

  .nav-mobile,
  .nav-burger {
    display: inherit;
  }

  .portfolio-info {
    display: none;
  }
}

@media (max-width: 600px) {
  .about-wrap {
    max-width: 100%;
  }

  .book-detail-title {
    font-size: 24px;
  }

  .book-quote {
    width: 90%;
  }

  .book-detail-info {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 702px) {
  .keywords-title {
    flex-basis: 100%;
  }
}

/* hack to maintain at least two items in the last row
 * except when every row has only one item */
@media (max-width: 1110px) and (min-width: 830px ) {
  .number-cards {
    width: 60%;
  }
}

@media (max-width: 410px) {
  .footer {
    left: 20px;
    right: 20px;
  }
}

@media (max-width: 1035px) {
  .book-detail-info {
    transform: translateY(0);
    padding-top: 0;
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (min-width: 600px) and (max-width: 1100px) {
  .book-quote {
    width: 80%;
  }
}

@media (max-height: 600px) {
  .cover-quote,
  .cover-author {
    display: none;
  }
}

@media (min-width: 992px) {
  .cv-teaching-details {
    columns: 2;
    list-style: inside disc;
  }
}

@media (max-width: 560px) {
  .github-cards {
    width: 90vw;
  }

  .skill-page .more-button {
    top: 700px;
  }

  .places-map-wrap {
    margin: 100px 0px 0px 0px;
  }

  .photography-btn-text {
    display: none;
  }
}

@media (hover: none) {
  .skill-switch:hover {
    transform: translateX(-72px);
  }
}

@media (orientation: portrait) {
  .flight-toggle-wrap {
    transform: translateX(-50%) translateY(30px);
  }
}
