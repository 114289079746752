@import url('https://fonts.loli.net/css?family=Lato:300,300i,400,700');
@import url('https://fonts.loli.net/css?family=Montserrat:700&text=ABCDEFGHIJKLMNOPQRSTUVWXYZops0123456789É!');
@import url('https://fonts.loli.net/css?family=Oswald:400,600');
@import url('https://fonts.loli.net/css?family=Exo+2:200&text=me%40yliu.io');
@import url('https://fonts.loli.net/css?family=Playfair+Display:700&text=‘');
@import url('https://fonts.loli.net/css?family=Heebo:300&text=g');
@import url('https://fonts.loli.net/css?family=Noto+Sans:200&subset=greek-ext');

body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif !important;
  font-weight: 300 !important;
}
